/** Since I've been unable to send uploaded file via traditional method
 * const formData = new formData();
 * formData.append("file", this.file);
 *
 * I've centralized method used by Thibault and consisting in parsing the file
 * and sending its content
 *
 * @param file      being the v-model of v-input-file's component.
 */
export const getUploadedFileContent = async (file) => {
  return new Promise((resolve, reject) => {
    if (file === null) {
      resolve(null);
      return;
    }
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      resolve(content);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file);
  });
};
