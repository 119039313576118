<template>
  <div>
    <h1 class="text-2xl mb-6">Analyse sémantique</h1>
    <!-- INFOS  -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Informations Compte Semrush
      </v-card-title>
      <v-row class="ml-3 mr-3">
        <v-text-field
          class="ml-3 mr-3"
          readonly
          outlined
          label="compte semrush"
          v-model="semrushAccount"
        >
        </v-text-field>
        <v-text-field
          class="ml-3 mr-3"
          readonly
          outlined
          label="solde du compte"
          v-model="semrushSold"
        >
        </v-text-field>
      </v-row>
    </v-card>
    <!-- INFOS  -->

    <!-- CONF GENERAL   -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-row class="ml-3 mr-3">
        <v-card-title class="text-h5 font-weight-bold"> Général </v-card-title>
      </v-row>
      <v-row class="ml-3 mr-3">
        <v-select
          class="ml-3 mr-3"
          :items="nbConc"
          v-model="nbConcSelected"
          label="Nombre concurrents"
          outlined
        ></v-select>
        <v-text-field
          class="ml-3 mr-3"
          v-model="posMax"
          outlined
          label="Position Max"
        ></v-text-field>
      </v-row>
      <v-row class="ml-3 mr-3">
        <v-checkbox
          class="ml-3 mr-3"
          v-model="fromApi"
          label="Export Via API"
        ></v-checkbox>
        <v-select
          class="ml-3 mr-3"
          :items="viaApiRowlimit"
          v-model="viaApiRowLimitSelected"
          label="Via api Row Limit"
          item-text="text"
          item-value="value"
          outlined
        >
        </v-select
      ></v-row>
    </v-card>
    <!-- CONF GENERAL   -->

    <!-- CONF SITE   -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Site
      </v-card-title>
      <v-row class="ml-3 mr-3">
        <v-text-field
          class="ml-3 mr-3"
          v-model="siteDomain"
          outlined
          label="Domain Url"
        ></v-text-field>
        <v-file-input
          accept=".csv"
          class="ml-3 mr-3"
          v-model="sitePosFileName"
          outlined
          label="Position file csv"
        ></v-file-input>
        <v-file-input
          accept=".csv"
          class="ml-3 mr-3"
          v-model="sitePagesFileName"
          outlined
          label="Page file csv"
        ></v-file-input>
        <v-file-input
          accept=".csv"
          class="ml-3 mr-3"
          v-model="siteURLexclues"
          outlined
          label="Url exclues File csv"
        ></v-file-input>
        <v-file-input
          accept=".csv"
          class="ml-3 mr-3"
          v-model="siteKeywordExclus"
          outlined
          label="KeyWord exclues File csv"
        ></v-file-input>
      </v-row>
    </v-card>
    <!-- CONF SITE   -->

    <!-- CONF CONCURRENTS -->
    <v-card
      class="pt-7 pb-3 mb-3"
      v-for="(concurrent, index) in concurrents"
      :key="index"
    >
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Concurrent {{ index + 1 }}
      </v-card-title>
      <v-row class="ml-3 mr-3">
        <v-text-field
          class="ml-3 mr-3"
          v-model="concurrent.domain"
          outlined
          label="Domain"
        ></v-text-field>
        <v-file-input
          accept=".csv"
          v-model="concurrent.PosFileName"
          class="ml-3 mr-3"
          outlined
          label="Position File Name"
        ></v-file-input>
        <v-file-input
          accept=".csv"
          v-model="concurrent.PagesFileName"
          class="ml-3 mr-3"
          outlined
          label="Pages File Name"
        ></v-file-input>
        <v-file-input
          accept=".csv"
          v-model="concurrent.URLexclues"
          class="ml-3 mr-3"
          outlined
          label="URL exclues File Name"
        ></v-file-input>
        <v-file-input
          accept=".csv"
          v-model="concurrent.KeywordExclus"
          class="ml-3 mr-3"
          outlined
          label="Keyword Exclus File Name"
        ></v-file-input>
      </v-row>
    </v-card>
    <!-- CONF CONCURRENTS -->

    <!-- GENERATION -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold">
        Génération
        <v-icon size="22" class="ml-6" @click="help = true">
          {{ icons.mdiHelpCircleOutline }}
        </v-icon>
      </v-card-title>

      <v-btn class="ml-6 mr-3" @click="sendData" color="primary">Process</v-btn>
      <v-btn class="ml-6 mr-3" @click="seeLogs" color="primary"
        >Display Logs</v-btn
      >
    </v-card>
    <!-- GENERATION -->

    <!-- Loading Spinner -->
    <v-dialog v-model="loading" max-width="100%">
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-row justify="center">
            <span>Loading...</span>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Loading Spinner -->

    <!-- Help Dialog -->
    <v-dialog v-model="help">
      <v-card class="pt-7 pb-3 mb-3">
        <read-me></read-me>
      </v-card>
    </v-dialog>
    <!-- Help Dialog -->

    <!-- Logs Dialog -->
    <v-dialog v-model="displayLogs">
      <v-card class="pt-7 pb-3 mb-3">
        <v-card-title class="text-h5 ml-3 mb-3 font-weight-bold"
          >Log File : {{ logFile }}</v-card-title
        >
        <pre v-text="logs"></pre>
      </v-card>
    </v-dialog>
    <!-- Logs Dialog -->

    <!-- Generation result -->
    <v-dialog v-model="seeResult" max-width="70vw">
      <v-card>
        <div v-if="data_processed.error">
          Erreur: {{ data_processed.error }}
        </div>
        <div v-else>Process succeeded</div>
      </v-card>
    </v-dialog>
    <!-- Generation result -->
  </div>
</template>

<script>
import axios from "@axios";
import { mdiHelpCircleOutline } from "@mdi/js";
import readMe from "@/views/seo/readMe.vue";
import { getUploadedFileContent } from "@/utils/upload";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "semantic-tools",
  components: { readMe },
  mounted() {
    saveRmraView(this.$route);
  },
  data() {
    return {
      icons: {
        mdiHelpCircleOutline,
      },
      data_processed: {},
      seeResult: false,
      displayLogs: false,
      logFile: "",
      logs: "",
      help: false,
      semrushAccount: "",
      semrushSold: "",
      loading: false,
      fromApi: false,
      nbConc: [2, 3, 4, 5, 6],
      nbConcSelected: "",
      viaApiRowlimit: [
        { value: "test", text: "test - 100" },
        { value: "light", text: "light - 20 000" },
        { value: "medium", text: "medium - 100 000" },
        { value: "full", text: "full - 200 000" },
      ],
      viaApiRowLimitSelected: "test",
      posMax: 30,
      siteDomain: "", // "www.melty.fr",
      sitePosFileName: null,
      sitePagesFileName: null,
      siteURLexclues: null,
      siteKeywordExclus: null,
      concurrents: [],
      readmeHtml: "",
    };
  },
  async created() {
    const response = await fetch("src/views/seo/readme.html");
    this.readmeHtml = await response.text();
    this.getApiSold();
  },
  watch: {
    nbConcSelected() {
      this.updateConcurrents();
    },
  },

  methods: {
    async seeLogs() {
      const response = await axios.get("seo/logs");
      this.logFile = response.data.logFile;
      this.logs = response.data.logs;
      this.displayLogs = true;
    },
    async executeWithLoading(asyncFunction) {
      this.loading = true;
      try {
        await asyncFunction();
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      } finally {
        this.loading = false;
      }
    },
    updateConcurrents() {
      this.concurrents = [];

      for (let i = 0; i < this.nbConcSelected; i++) {
        this.concurrents.push({
          domain: "",
          PosFileName: null,
          PagesFileName: null,
          URLexclues: null,
          KeywordExclus: null,
        });
      }
    },
    async getApiSold() {
      const response = await axios.get("seo/api-sold");
      this.semrushSold = response.data.solde;
      this.semrushAccount = response.data.compte;
    },
    async process_conc_csv() {
      for (let i = 0; i < this.concurrents.length; i++) {
        const concurrent = this.concurrents[i];
        concurrent.domain = concurrent.domain.trim();
        concurrent.PosFileName = await getUploadedFileContent(
          concurrent.PosFileName
        );
        concurrent.PagesFileName = await getUploadedFileContent(
          concurrent.PagesFileName
        );
        concurrent.URLexclues = await getUploadedFileContent(
          concurrent.URLexclues
        );
        concurrent.KeywordExclus = await getUploadedFileContent(
          concurrent.KeywordExclus
        );
      }
    },
    async process() {
      // await this.executeWithLoading(async () => {
      this.loading = true;
      await this.process_conc_csv();
      const sitePos = await getUploadedFileContent(this.sitePosFileName);
      const sitePage = await getUploadedFileContent(this.sitePagesFileName);
      const siteUrl = await getUploadedFileContent(this.siteURLexclues);
      const siteKw = await getUploadedFileContent(this.siteKeywordExclus);
      const data_site = {
        fromApi: this.fromApi,
        nbConcSelected: this.nbConcSelected,
        viaApiRowlimit: this.viaApiRowLimitSelected,
        posMax: this.posMax,
        siteDomain: this.siteDomain,
        sitePosFile: sitePos,
        sitePageFile: sitePage,
        siteUrlFile: siteUrl,
        siteKwFile: siteKw,
        concurrents: this.concurrents,
      };
      console.log("data_site", data_site);
      const response = await axios.post("seo/process", data_site);
      this.data_processed = response.data;
      console.log("data_processed", this.data_processed);
      await this.$store.dispatch("seo/setData", response.data);
      // });
      this.loading = false;
      this.seeResult = true;
    },
    checkFile() {
      if (this.sitePosFileName === null) {
        alert("Veuillez choisir un fichier position pour le site audité");
        return false;
      }
      if (this.sitePagesFileName === null) {
        alert("Veuillez choisir un fichier pages pour le site audité");
        return false;
      }
      for (let i = 0; i < this.concurrents.length; i++) {
        if (this.concurrents[i].PosFileName === null) {
          alert("Veuillez choisir un fichier position pour chaque concurrent");
          return false;
        }
        if (this.concurrents[i].PagesFileName === null) {
          alert("Veuillez choisir un fichier pages pour chaque concurrent");
          return false;
        }
      }
      return true;
    },
    checkString(str) {
      try {
        // new URL(str);
        const urlPattern = new RegExp("^(https://|www)", "i");
        if (urlPattern.test(str)) {
          console.log("valid url");
          return "Valid URL";
        } else {
          console.log("invalid url");
          return "Invalid URL";
        }
      } catch (_) {
        console.log("invalid url");
        return "Invalid URL";
      }
    },
    async sendData() {
      if (this.checkString(this.siteDomain) === "Invalid URL") {
        alert("Veuillez saisir une URL valide");
        return;
      }
      if (this.fromApi) {
        if (
          confirm(
            "ATTENTION !! \n\nEn passant par l'API, chaque export est PAYANT. \nPour plus d'info cliquer sur l'info-bulle. \n\nCliquez sur OK pour lancer l'export"
          )
        ) {
          this.process();
        }
      } else {
        if (this.checkFile()) {
          this.process();
        }
      }
    },
  },
};
</script>

<style scoped></style>
