<template>
  <div>
    <h1>Export SEO Sémantique</h1>

    <h2>Liens utiles</h2>
    <ul>
      <li>
        <a href="https://developer.semrush.com/api/basics/how-to-get-api/"
          >Documentation Semrush API</a
        >
      </li>
      <li>
        <a
          href="https://docs.google.com/spreadsheets/d/1v2dpoaP9M7JL1LKl4H5nuMFhz1mhjkBcLC6DSOh_dh8/edit#gid=0"
          >Spreadsheet "blueprint" du projet</a
        >
      </li>
      <li>
        <a
          href="https://drive.google.com/drive/folders/1vJWg-p_HpZhlvNyt1aPgfPXtSWsco64M"
          >Dossier drive dataman du projet</a
        >
      </li>
      <li>
        <a href="https://fr.semrush.com/projects/"
          >Client Semrush pr les exports csv</a
        >
      </li>
    </ul>

    <h2>Notes</h2>
    <ul>
      <li>
        1 API unit = 50/1000000 = 0.00005€ -&gt; 200 000 lignes = 10€ (pas
        précis, dépend du nombre achetés)
      </li>
      <li>
        3 méthodes de créations des onglets:
        <ul>
          <li>Via API -&gt; A partir de l'api semrush</li>
          <li>Via CSV -&gt; A partir dèun export csv du client</li>
          <li>
            Via computed_sheet -&gt; A partir des données des onglets précédents
          </li>
        </ul>
      </li>
    </ul>

    <h2>Onglets</h2>

    <p><em>Méthodes et règles de récupération des données par onglets</em></p>

    <h3>Site_positions</h3>
    <ol>
      <li>
        <strong>Présentation</strong>
        <ul>
          <li>Equivalent de l'onglet "Site"</li>
          <li>
            méthodes:
            <ul>
              <li>Via api</li>
              <li>Via csv</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <strong>Règles et Process</strong>
        <ul>
          <li>on exclut les positions supérieures à posMax</li>
          <li>on trie par ordre décroissant de search volume</li>
          <li>
            on exclut les keywords recencés dans le fichier confs
            (keyword_exclus).
          </li>
          <li>
            on exclut les urls recensées dans le fichier confs (url_exclues).
          </li>
          <li>selection des colonnes + ajout des colonnes catégories</li>
        </ul>
      </li>
      <li>
        <strong>Notes et questions</strong>
        <ul>
          <li>
            export csv limité à 50 000 lignes sur client (possibilité rapport
            personalisé ?)
          </li>
        </ul>
      </li>
    </ol>

    <h3>Site_pages</h3>
    <ol>
      <li>
        <strong>Présentation</strong>
        <ul>
          <li>Equivalent de l'onglet "URL Site"</li>
          <li>
            méthodes:
            <ul>
              <li>Via csv</li>
              <li>computed_sheet</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <strong>Règles et Process</strong>
        <ul>
          <li>
            On exclut les URL recencées dans le fichier confs (url_exclues)
          </li>
          <li>On trie par ordre décroissant de nb_keywords</li>
          <li>selection des colonnes</li>
        </ul>
      </li>
    </ol>

    <h3>Data Site</h3>
    <p><em>Seulement en mode computed_sheet pour le moment</em></p>
    <p><em>On décompose l'onglet "data site" en 3 onglets</em></p>

    <h4>Top 10 Urls</h4>
    <ol>
      <li>
        <strong>Règles et Process</strong>
        <ul>
          <li>
            On prend les 10 premières urls de l'onglet "url site" en terme de
            Traffic (col traffic semrush)
          </li>
          <li>
            Pour chaque URL, on prend les 10 premiers keywords en terme de
            search volume
          </li>
        </ul>
      </li>
    </ol>

    <h4>Position potentiels</h4>
    <ol>
      <li>
        <strong>Règles et Process</strong>
        <ul>
          <li>
            Pour chaque tranche de positions, on prend les 10 premiers keywords
            en terme de traffic (col traffic de semrush)
          </li>
        </ul>
      </li>
    </ol>

    <h4>Potentiel par KD</h4>
    <ol>
      <li>
        <strong>Règles et Process</strong>
        <ul>
          <li>
            Pour chaque tranche de KD, on prend les 10 premiers keywords en
            terme de traffic (col traffic de semrush)
          </li>
        </ul>
      </li>
    </ol>

    <h3>Canibalisatiion site</h3>
    <ol>
      <li>
        <strong>Règles et Process</strong>
        <ul>
          <li>
            On regroupe les colonnes de l'onglet "Site_positions" par keyword.
          </li>
          <li>
            On garde l'URL avec le plus gros traffic comme URL de référence pour
            le keyword.
          </li>
        </ul>
      </li>
    </ol>

    <h3>Onglets concurrents</h3>
    <p><em>Mêmes onglets que "Site_" mais pour les "Concurrents_"</em></p>

    <h3>Onglet comparatif</h3>
    <p><em>Synthèse des onglets "Data consolidées" et "comparatif"</em></p>

    <ol>
      <li>
        <strong>Règles et Process</strong>
        <ul>
          <li>
            all_keywords : concaténation verticale des onglets "positions" du
            site et des concurrents
          </li>
          <li>
            Delete doublons sur keyword
            <ul>
              <li>
                Concernant concurrents: on garder la correspondance max pour les
                colonnes positions concurrents
              </li>
              <li>
                Concernant Site: on garde l'url/search_vol/kw diff
                correspondaant à la position max
              </li>
            </ul>
          </li>
          <li>
            colonne acteurs positionnés: on compte le nb de colonnes position >
            0
          </li>
        </ul>
      </li>
    </ol>

    <h3>Onglet quickwin</h3>
    <ol>
      <li>
        <strong>Règles et Process</strong>
        <ul>
          <li>pages de Site_positions avec pos 4-10 et kd&lt;=25</li>
          <li>pages de Site_positions avec position 3-20 et kd&lt;=50</li>
          <li>
            pages de df_comparatif
            <ul>
              <li>consition 1: KD=&lt;30 / une position site en 4-11</li>
              <li>
                condition 2: on est moins bien poistionné qu'un des concurrents
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ol>

    <h2>Notes et développements futurs</h2>
    <ul>
      <li>Retour utilisation - le + important - pr dvpt futurs et fixs</li>
      <li>Mots clés redondants et inclusions url</li>
      <li>
        rajouter limitations pr éviter pb de chargement / ou rajouter pagination
        (DB)/ (out of memory)
      </li>
      <li>
        Desactiver le cache ou mettre date avec la clé (mois?, jour?, ...)
      </li>
      <li>Nommer et sauvegarder un rapport (autrement que csv) ?</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "readMe",
};
</script>

<style scoped>
/* ... */
</style>
